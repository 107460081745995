/* Container to hold both image and text */
    .about-container {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 1200px; /* Control the max-width of the section */
        margin: 50px auto; /* Center the entire section */
        padding: 20px;
        background-color: #f8f8f8;
        border-radius: 15px;
    }
    
    /* Image Styling */
    .about-img-wrapper {
        flex: 1;
        padding: 20px;
    }
    
    .about-img {
        width: 100%;
        max-width: 400px; /* Control the max width of the image */
        border-radius: 15px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border: 3px solid #e8b634; /* Optional: to match the yellow border */
    }
    
    /* Text Section Styling */
    .about-text {
        flex: 2;
        padding: 20px;
    }
    
    .about-text h2 {
        font-size: clamp(1.5rem, 2vw + 1rem, 3.5rem);
        margin-bottom: 10px;
    }
    
    .about-text h4 {
        font-size: clamp(1.5rem, 2vw + 1rem, 3.5rem);
        color: #666;
        margin-bottom: 20px;
    }
    
    .about-text p {
        font-size: clamp(1.5rem, 2vw + 1rem, 3.5rem);
        line-height: 1.6;
        color: #333;
    }
    