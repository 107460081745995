/* Admin Page Container */
.admin-page {
    background-color: #f5f5f5; /* Light grey background for the admin area */
    padding: 30px;
    max-width: 800px;
    margin: 20px auto; /* Center the admin page */
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Admin Form Container */
.admin-product-form {
    background-color: #fff; /* White background for the form */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

/* Form Heading */
.admin-product-form h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 15px;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
}

/* Form Labels */
.admin-product-form label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    display: block;
}

/* Form Inputs and Textareas */
.admin-product-form input,
.admin-product-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    background-color: #f9f9f9;
}

/* Add Product Button */
.admin-product-form button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #28a745; /* Green color */
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-product-form button:hover {
    background-color: #218838; /* Darker green on hover */
}

/* Product List Container */
.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

/* Product Item Styling */
.product-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.product-item img {
    width: 100%;
    height: 200px; /* Fixed height */
    object-fit: cover; /* This will maintain aspect ratio while covering the container */
    border-radius: 4px;
}

.product-item span {
    flex: 1; /* Takes available space to push buttons apart */
    text-align: left;
}

.product-item:hover {
    background-color: #e9e9e9; /* Slight color change on hover */
}

/* Edit and Delete Buttons */
.product-item button {
    padding: 8px 12px;
    font-size: 13px;
    color: rgb(238, 238, 243);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 5px;
}

.edit{
    color: #fff;
    background-color: #007bff; /* Blue */
    border: none;
}

.product-item button:first-child {
    background-color: #007bff; /* Blue for edit */
}

.product-item button:first-child:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

.product-item button:last-child {
    background-color: #dc3545; /* Red for delete */
}

.product-item button:last-child:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Styling for the Cancel Edit button */
.cancel-edit-button {
    background-color: #ffc107; /* Warning yellow */
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cancel-edit-button:hover {
    background-color: #e0a800; /* Darker yellow on hover */
}

.admin-event-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
}

.admin-event-form .form-group {
    margin-bottom: 15px;
}

.admin-event-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.admin-event-form input,
.admin-event-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.admin-event-form textarea {
    min-height: 100px;
}

.admin-event-form button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.admin-event-form button:hover {
    background: #0056b3;
}

.admin-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
}

.admin-form .form-group {
    margin-bottom: 15px;
}

.admin-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.admin-form input,
.admin-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.admin-form input[type="file"] {
    padding: 4px;
}

.admin-form textarea {
    min-height: 100px;
    resize: vertical;
}

.admin-form button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.admin-form button:hover {
    background: #0056b3;
}

.product-list {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.product-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.product-item button {
    margin-left: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.product-item button.edit {
    background: #28a745;
    color: white;
}

.product-item button:last-child {
    background: #dc3545;
    color: white;
}

/* Add these to your existing CSS */
.error {
    border-color: #dc3545 !important;
    background-color: #fff8f8;
}

.error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}

.image-preview {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f9fa;
    text-align: center;
}

.image-preview img {
    width: 100%;
    height: 200px; /* Match the product list image height */
    object-fit: cover;
    border-radius: 4px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    color: #333;
}

.form-group input[type="file"] {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Image Preview Styles */
.image-preview {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f8f9fa;
    text-align: center;
}

.image-preview img {
    width: 100%;
    height: 200px; /* Match the product list image height */
    object-fit: cover;
    border-radius: 4px;
    margin: 0 auto;
}

/* Form Image Preview */
.form-group .image-preview img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .product-list {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .product-item img,
    .image-preview img,
    .form-group .image-preview img {
        height: 150px; /* Smaller height for mobile */
    }
}