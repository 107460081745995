/* Container for the tabs */
.tabs-container {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
}

/* General Tab Styles */
.tab {
    width: 60px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease, filter 0.3s ease;
}

/* Hover Effect for Tabs */
.tab:hover {
    background-color: #e8b634;
}

/* General Tab Icon Styles */
.tab-icon {
    width: 50px; /*  width for the icons */
    height: 40px; /* height for the icons */
    object-fit: contain; /* Make sure the image scales to fit the container */
}

/* Active Tab Styles */
.tab-active {
    background-color: #e8b634; /* Bright yellow for active state */
}

/* Inactive Icon Styles */
.tab img {
    filter: grayscale(100%); /* Makes the icon black and white when inactive */
}

/* Active Icon Styles */
.tab-active img {
    filter: none; /* Restore the original color of the icon when active */
}

/* Tab label styling */
.tab-label {
    margin-top: 5px; /* Space between the icon and the label */
    font-size: clamp(0.8rem, 1vw, 1.0rem); /* Responsive font */ /* Font size for the label */
    color: #333; /* Text color */
    text-align: center;
}
